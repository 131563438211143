import _mustache from "mustache";
import _asyncFile from "async-file";
import _getFileSum from "./getFileSum";
import _getSum from "./getSum";
var exports = {};
const mustache = _mustache;
const fs = _asyncFile;
const getFileSum = _getFileSum;
const getSum = _getSum;
/** Copy a file from source frame to it's destination with parsing */

exports = async function copyFrameFile(source, destination, data, dryRun) {
  const content = await fs.readFile(source, "utf8"); // assuming utf8?

  const rendered = mustache.render(content, data);

  if (!dryRun) {
    await fs.writeFile(destination, rendered, "utf8"); // assuming utf8?
  } else {
    return getSum(rendered);
  }

  return getFileSum(destination);
};

export default exports;