import _fs from "fs";
import _path from "path";
var exports = {};

function _nullRequire(id) {
  var e = new Error("Cannot find module '" + id + "'");
  e.code = "MODULE_NOT_FOUND";
  throw e;
}

const fs = _fs;
const path = _path;
const PACKAGE_FILENAME = "package.json";
/** Get package json */

exports = function getPackage(project) {
  const packagePath = path.join(project, PACKAGE_FILENAME);

  if (fs.existsSync(packagePath)) {
    return _nullRequire(packagePath);
  }

  return null;
};

export default exports;