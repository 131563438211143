import _path from "path";
import _asyncFile from "async-file";
import _sortObject from "sort-object";
import _LOCK_FILENAME from "./LOCK_FILENAME";
var exports = {};
const path = _path;
const fs = _asyncFile;
const sortObj = _sortObject;
const LOCK_FILENAME = _LOCK_FILENAME;
/** Write a new lock file */

exports = async function writeLock(project, newName, newVersion, copiedFiles, skippedFiles) {
  const newLock = {
    name: newName,
    version: newVersion,
    files: {}
  };
  copiedFiles.forEach(([file, sum]) => newLock.files[file] = sum);
  skippedFiles.forEach(([file, sum]) => newLock.files[file] = sum);
  newLock.files = sortObj(newLock.files); // sort to maintain prettier diffs

  const content = JSON.stringify(newLock, null, 2);
  const destination = path.join(project, LOCK_FILENAME);
  return fs.writeFile(destination, content, "utf8");
};

export default exports;