import _fs from "fs";
import _path from "path";
import _LOCK_FILENAME from "./LOCK_FILENAME";
var exports = {};

function _nullRequire(id) {
  var e = new Error("Cannot find module '" + id + "'");
  e.code = "MODULE_NOT_FOUND";
  throw e;
}

const fs = _fs;
const path = _path;
const LOCK_FILENAME = _LOCK_FILENAME;
/** Get lock json */

exports = function getLock(project) {
  const lockPath = path.join(project, LOCK_FILENAME);

  if (fs.existsSync(lockPath)) {
    return _nullRequire(lockPath);
  }

  return null;
};

export default exports;