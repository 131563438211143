import _resolve from "resolve";
var exports = {};
const resolve = _resolve;
/** Resolve a frame directory from the given basedir */

exports = function resolveFrame(frame, basedir) {
  return resolve.sync(frame, {
    basedir: basedir,
    isFile: () => true,
    extensions: [""]
  });
};

export default exports;