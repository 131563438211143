import _recursiveReaddir from "recursive-readdir";
import _path from "path";
import _IGNORED_FILES from "./IGNORED_FILES";
var exports = {};
const recursiveReaddir = _recursiveReaddir;
const path = _path;
const IGNORED_FILES = _IGNORED_FILES;
/** Get appropriate list of relative file paths in directory to be framed */

exports = async function getFrameFiles(inPath, exclude) {
  const files = await recursiveReaddir(inPath, [...IGNORED_FILES, ...exclude]);
  return files.map(file => path.relative(inPath, file));
};

export default exports;