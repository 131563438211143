import _path from "path";
import _cosmiconfig from "cosmiconfig";
import _npmlog from "npmlog";
import _lodash from "lodash.difference";
import _asyncFilter from "./asyncFilter";
import _findFrameFiles from "./findFrameFiles";
import _copyFrameFile from "./copyFrameFile";
import _resolveFrame from "./resolveFrame";
import _getLock from "./getLock";
import _getPackage from "./getPackage";
import _writeLock from "./writeLock";
import _isFileOverridden from "./isFileOverridden";
import _getFileSum from "./getFileSum";
var exports = {};
const path = _path;
const cosmiconfig = _cosmiconfig;
const log = _npmlog;
const diff = _lodash;
const asyncFilter = _asyncFilter;
const findFrameFiles = _findFrameFiles;
const copyFrameFile = _copyFrameFile;
const resolveFrame = _resolveFrame;
const getLock = _getLock;
const getPackage = _getPackage;
const writeLock = _writeLock;
const isFileOverridden = _isFileOverridden;
const getFileSum = _getFileSum; // TODO: Separate targeting files to copy with the actual copy and lock write
//       This will allow in the cli to be like "are you sure you want to do x?"
// TODO: With the above we should warn the user if they are about to overwrite
//       some files which weren't previously framed.

/** Copy files from our source frame to our project destination */

exports = async function copyFrame(project, frameOverride, force, forceOverride, dryRun) {
  // TODO: Frame is configured usually
  // TODO: If frame name/path+version hasn't changed we do nothing
  const {
    config = {}
  } = await cosmiconfig("frame").load(project);
  const exclude = config.exclude || [];
  const frame = frameOverride || config.source;
  const source = resolveFrame(frame, project);
  const lock = getLock(project);
  const framePkg = getPackage(source);

  if (!framePkg) {
    throw new Error(`Could not find package.json for frame \`${frame}\``);
  }

  if (lock) {
    if (framePkg.version === lock.version && framePkg.name === lock.name && !force) {
      log.warn(`No new frame version - maybe you need to \`npm install ${lock.name}@latest\` first?`, `${lock.name}@${lock.version}`);
      return;
    } else {
      log.info(`Updating frame`, `${lock.name}@${lock.version} -> ${framePkg.name}@${framePkg.version}`);
    }
  } else {
    log.info(`Initializing frame`, `${framePkg.name}@${framePkg.version}`);
  }

  const pkg = getPackage(project);
  const data = Object.assign({}, {
    pkg
  }, config.data);
  const files = await findFrameFiles(source, exclude);
  const copyArgs = files.map(file => [file, path.join(source, file), path.join(project, file), data, dryRun]);
  const filteredArgs = forceOverride ? copyArgs : await asyncFilter(copyArgs, ([file, source, destination]) => isFileOverridden(lock, file, destination).then(is => !is));
  filteredArgs.forEach(([file]) => log.verbose(`Copying file`, file));
  const skippedFiles = await Promise.all(diff(copyArgs, filteredArgs).map(([file, source]) => getFileSum(source).then(sum => [file, sum])));
  skippedFiles.forEach(([file]) => log.warn(`Skipped overridden file`, file));
  const copiedFiles = await Promise.all(filteredArgs.map(([file, ...args]) => copyFrameFile(...args).then(sum => [file, sum])));
  copiedFiles.forEach(([file]) => log.info(`Copied file`, file));
  const write = dryRun ? true : await writeLock(project, framePkg.name, framePkg.version, copiedFiles, skippedFiles);
  log.info("Wrote lock file");
  return write;
};

export default exports;